.restriction_container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99999999;
  background: rgb(0 0 0 / 80%);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999999;
}

.restriction_content {
  padding: 32px 24px;
  background: #13131a;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 433px;

  h2 {
    text-align: center;
    line-height: 24px;
    font-weight: 800;
    margin-bottom: 24px;
    color: #ff3a2e;
    margin-bottom: 12px;
  }
}

.subtitle {
  font-weight: 600;
  color: #fff;
  line-height: 24px;
  text-align: center;
}

.text {
  color: #888897;
  font-weight: 600;
  margin: 10px 0 16px 0;
  text-align: center;
  line-height: 20px;
}

.actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}

.actions button {
  font-size: 12px;
  line-height: 24px;
  font-weight: 800;
  height: 36px;
  color: #888897;
  cursor: pointer;
}

.actions button:first-child {
  margin-right: 8px;
}

@media (max-width: 500px) {
  .restriction_content {
    width: 80%;
  }
}

@media (max-width: 350px) {
  .actions {
    flex-direction: column;
  }
  .actions button:first-child {
    margin-bottom: 8px;
  }
}

@media (max-height: 650px) {
  .restriction_content {
    height: 80vh;
    overflow: scroll;
  }
}
