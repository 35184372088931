.cookie_container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: rgba(14, 13, 15, 0.85);
  z-index: 9999999999;
}


.cookie_wrapper {
  max-width: 525px;
  margin: 32px auto;
  text-align: center;
}

.cookie_wrapper button {
  width: 180px;
  margin: auto;
  height: 42px;
  font-weight: 600;
}

.cookie_title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 8px;
}

.cookie_subtitle, .danger {
  font-size: 12px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 600;
  margin-bottom: 24px;

  a {
    color: #FF3A2E;
  }
}

.danger {
  color: #FF3A2E;
  margin-left: 5px;
  cursor: pointer;
}

@media (max-width: 650px) {
  .cookie_wrapper {
    padding: 0 10px;
  }
  .cookie_wrapper button {
    width: 148px;
    height: 30px;
    font-size: 14px;
  }
}