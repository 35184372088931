.secondary_btn {
  color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #1e1e23;
}

.secondary_btn_md {
  padding: 14px 3px;
  border-radius: 30px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 800;
}
