.danger_btn {
  background: #ff3a2e !important;
  color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.danger_btn:disabled {
  opacity: 0.5;
}

.danger_btn_md {
  padding: 14px 3px;
  border-radius: 30px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 800;
}

.danger_btn_sm {
}
.danger_btn_lg {
}
