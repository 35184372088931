
.interested_container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99999999;
  background: rgb(0 0 0 / 80%);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.interested_content {
  padding: 32px;
  background: #111117;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 24px;
  }
}

.interested_logo {
  width: 202px;
  margin-bottom: 12px;
}

.interested_wrapper {
  display: flex;
  width: 396px;
  flex-wrap: wrap;
  align-items: center;
}

@media (max-width: 500px) {
  .interested_content {
    width: 80%;
  }
}

@media (max-width: 440px) {
  .interested_wrapper{
    flex-direction: column;
  }
}

@media (max-width: 440px) {
  .interested_content {
    width: 70%;
  }
  .interested_wrapper{
    width: 100%;
  }
}