.button {
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  font-family: "Gilroy", sans-serif;
  min-width: 64px;
  border-radius: 30px;
}

.button--primary {
  background-color: #ff3a2e;
  color: #ffffff;
  border: 0;

  &:disabled {
    opacity: 0.5;
  }
}

.button--secondary {
  background-color: #21212a;
  color: #fff;
  border: 0;
}

.button--outlined {
  background-color: transparent;
  color: #ff3a2e;
  border: 1px solid #ff3a2e;
}

.button--sm {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.285717;
  padding: 7px 12px;
}

.button--md {
  font-weight: 600;
  font-size: 16px;
  padding: 14px 24px;
  line-height: 1.333333333;
  min-height: 52px;
}

.button--lg {
  font-weight: 800;
  font-size: 18px;
  padding: 14px 24px;
  line-height: 1.333333333;
}

.button--full-width {
  width: 100%;
}

.loader {
  width: 20px;
  height: 20px;
  border: 3px solid #fff;
  border-bottom-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1.25s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
