.interested_title {
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 12px;

  h5 {
    margin-left: 8px;
    color: #fff;
    line-height: 19px;
    font-weight: 600;
    white-space: nowrap;
    opacity: .7;
  }
}

.interested_container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 190px;
  height: 161px;
  border-radius: 30px;
 
  margin-bottom: 10px;
  border: 2px solid #111117;;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.interested_container:hover::before{
  content: '';
  background-image: url("@assets/user/female_interested_bg-hover.png");
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  background-repeat: no-repeat;
}

.interested_container:hover {
  border-color: #fff;
  transition: .3s;

  h5 {
    opacity: 1;
  }
}

.interested_container:nth-child(2n) {
  margin-left: 8px;
}

.interested_container:nth-child(2n):hover::before{
  content: '';
  background-image: url("@assets/user/male_interested_bg-hover.png");
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
}

@media (max-width: 1100px) {
  .interested_container::before {
    content: '';
    background-image: url("@assets/user/female_interested_bg-hover.png");
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .interested_container:nth-child(2n)::before{
    background-image: url("@assets/user/male_interested_bg-hover.png");
  }

  .interested_container {
    border: none;

    h5 {
      opacity: 1;
    }
  }
}