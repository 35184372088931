.outlined_btn {
  background: transparent;
  border: 1px solid #ff3a2e;
  border-radius: 30px;
  color: #ff3a2e;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.outlined_btn_md {
  padding: 8px 12.5px;
  font-size: 14px;
  font-family: "Gilroy";
  font-weight: 600;
  line-height: 16.7px;
}
