
/*300*/
@font-face {
  font-family: "Gilroy";
  font-weight: 300;
  src: local("Gilroy-Light"), url(./fonts/Gilroy-Light.ttf) format("truetype");
}

/*400*/
@font-face {
  font-family: "Gilroy";
  font-weight: 400;
  src: local("Gilroy-Regular"), url(./fonts/Gilroy-Regular.ttf) format("truetype");
}

/*500*/
@font-face {
  font-family: "Gilroy";
  font-weight: 500;
  src: local("Gilroy-Medium"), url(./fonts/Gilroy-Medium.ttf) format("truetype");
}

/*600*/
@font-face {
  font-family: "Gilroy";
  font-weight: 600;
  src: local("Gilroy-SemiBold"), url(./fonts/Gilroy-SemiBold.ttf) format("truetype");
}

/*700*/
@font-face {
  font-family: "Gilroy";
  font-weight: 700;
  src: local("Gilroy-Bold"), url(./fonts/Gilroy-Bold.ttf) format("truetype");
}

/*800*/
@font-face {
  font-family: "Gilroy";
  font-weight: 800;
  src: local("Gilroy-ExtraBold"), url(./fonts/Gilroy-ExtraBold.ttf) format("truetype");
}

html,
body {
  block-size: 100%;
}

html,
body,
#root {
  height: 100%;
  background: #0c0c0d;
  font-family: "Gilroy", sans-serif;
  font-weight: 400;
  font-size: 14px;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  display: none;
}

html.modal-open,
body.modal-open {
  overflow: hidden;
  height: 100%;
  touch-action: none; /* for iOS */
  position: fixed;
  width: 100%;
}

/* Для Firefox */
* {
  scrollbar-width: none;
}

/* Для Internet Explorer/Edge */
body {
  -ms-overflow-style: none;
}

#root {
  display: flex;
  justify-content: center;
}

a,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  color: white;
  font-family: "Gilroy", sans-serif;
}

button,
input {
  font-family: "Gilroy", sans-serif;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

a {
  display: inline-block;
  font-size: 16px;
  text-decoration: none;
}

.rc-dropdown {
  z-index: 99999!important
  ;

  ul.rc-menu {
    background-color: #21212a;
    border-radius: 15px;
    padding: 16px;
    box-shadow: none;
    border: none;
    right: 0px;
    z-index: 9992;

    .rc-menu-item {
      a {
        margin-bottom: 10px;
      }

      span {
        color: #888897;
      }
    }
  }

  .rc-menu-item:last-child {
    .nav_item {
      margin-bottom: 0;
    }
  }

  .nav_item {
    div {
      height: 20px;
      width: 20px;
      background-color: transparent;
      margin-right: 10px;
    }
  }
}

.askme_active {
  ul.rc-menu {
    top: -13px;
  }
  .rc-menu-item {
    padding: 5px !important;

    img {
      margin-top: 2px;
    }
  }

  .rc-menu-item:hover {
    span {
      color: #fff !important;
    }
  }

  .rc-menu-item:nth-child(4n) {
    padding-bottom: 13px !important;
  }

  .rc-menu-item:last-child {
    padding-top: 13px !important;
    padding-bottom: 0 !important;
    position: relative;
  }

  .rc-menu-item:last-child::before {
    content: "";
    background: #ffffff17;
    position: absolute;
    top: 0;
    height: 1px;
    width: 128%;
    right: 0;
    left: -16px;
  }
}

.token_menu_active {
  ul.rc-menu {
    right: 0;
  }

  .rc-menu-item {
    width: 100%;
    span {
      font-size: 14px !important;
      line-height: 16px !important;
      color: #ffffff !important;
      font-weight: 600;
    }

    a {
      width: 100%;
      height: 31px;
      display: flex;
      justify-content: center;
      margin-bottom: 0 !important;
    }
  }
}

.gallery_filter_active {
  .rc-menu-item {
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #888897;
    }

    button {
      img {
        opacity: 0;
      }
    }

    button:hover span {
      color: #fff;
      transition: 0.3;
    }
  }

  .rc-menu-item.active {
    button {
      img {
        opacity: 1;
      }
      span {
        color: #fff;
      }
    }
  }

  .rc-menu-item:first-child {
    margin-bottom: 10px;
  }
}

.profile_filter_active {
  .rc-menu-item {
    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #888897;
    }

    button {
      width: 100%;

      img {
        opacity: 0;
      }
    }
  }

  .rc-menu-item.active {
    button {
      img {
        opacity: 1;
      }
      span {
        color: #fff;
      }
    }
  }

  .rc-menu-item:first-child {
    margin-bottom: 10px;
  }
}

.chat_restart_active {
  ul.rc-menu {
    right: 100%;
    margin-right: -17px;
    padding-bottom: 6px;

    .rc-menu-item {
      margin-bottom: 10px;

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #888897;
      }
    }

    .rc-menu-item:first-child button::before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url("@assets/restart.svg");
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 4px;
      margin-left: -5px;
    }

    .rc-menu-item:nth-child(2) button::before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url("@assets/menu_trash.svg");
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 4px;
      margin-left: -5px;
      margin-top: -3px;
    }

    .rc-menu-item.rc-menu-item-active {
      span {
        color: #fff;
        transition: 0.3s;
      }
    }

    .rc-menu-item.rc-menu-item-active:first-child button::before {
      background-image: url("@assets/restart_active.svg");
    }

    .rc-menu-item.rc-menu-item-active:nth-child(2) button::before {
      background-image: url("@assets/trash_red.svg");
    }
  }
}

.chat_delete_message {
  ul.rc-menu {
    right: 0px;
    width: 100px;
    right: 0;
    margin-left: auto;

    .rc-menu-item {
      margin-bottom: 0;
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #fff;
      }
    }

    .rc-menu-item.rc-menu-item-active {
      span {
        color: #fff;
        transition: 0.3s;
      }
    }
  }

  .rc-menu-item.active {
    button {
      img {
        opacity: 1;
      }
      span {
        color: #fff;
      }
    }
  }

  .rc-menu-item:first-child {
    margin-bottom: 10px;
  }
}

.payment #root > div {
  background: white;
  max-width: 100%;
}

.ls {
  letter-spacing: 0.9px;
}

.danger_btn-danger {
  span {
    color: #fff !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px #1e1e23 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.infinite-scroll-component__outerdiv {
  margin-bottom: auto;
}

::-webkit-scrollbar {
  width: 0;  
  height: 0;
  display: none;
}

::-webkit-scrollbar-thumb {
  background: transparent; 
}

.bundle-gallery {
  .slick-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    box-shadow: 0px 6.67px 13.33px 0px #00000040;
    background: #2E2E3E99;
    border-radius: 50%;
    
    img { 
      rotate: 90deg;
    }

    &::before {
      display: none;
    }
  }
  
}

.private_content_slider .slick-dots {
  position: absolute;
  bottom: 20px;

  li {
    width: 5px;

    button::before {
      font-size: 9px;
      color: #d9d9d9;
    }
  }

  .slick-active {
    button::before {
      color: #FF3A2E;
    }
  }
}

.loader_bg_with_aside {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 230px;
  background: #0c0c0d;
}

@media (max-width: 1450px) {
  .gallery_filter_active {
    ul.rc-menu {
      right: 30px;
    }
  }
}

@media (max-width: 1100px) {
  .chat_restart_active {
    ul.rc-menu {
      right: 0;
      margin-right: 0;
    }
  }

  .loader_bg_with_aside {
    left: 0;
  }
}
