.root__container {
  background: #0C0C0D;
  padding: 20px 20px 0 20px;
  max-width: 1920px;
  width: 100%;
  overflow-x: hidden;
  scrollbar-width: none;
}

@media (max-width: 690px) {
  .root__container {
    padding: 7px 0px;
  }
 }